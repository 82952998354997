import React from "react";

function Menu() {
return <div className="menu">
    <ul>
        <li><a href="#download">Download</a></li>
        <li><a href="#features">Features</a></li>
        <li><a href="#about">About</a></li>
    </ul>
</div>

}

export default Menu;