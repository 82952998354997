import React from "react";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contact() {
return <section className="contact" id="contact">
<p>Copyright 2021 - Kristian Slaatsveen</p>

    
</section>
}

export default Contact;